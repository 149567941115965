import { createSlice } from '@reduxjs/toolkit';
// app slice for loading state and user authentication flag
const initialState = {
  isLoading: false,
  isAuthenticated: false,
  message: ''
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    }
  }
});
export const { appLoading, setMessage, setIsAuthenticated } = appSlice.actions;

export default appSlice.reducer;
